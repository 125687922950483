import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";

import log from "loglevel";

import { Header } from "./components/Header.jsx";
import { Footer } from "./components/Footer.jsx";
import { PersonDetails } from "./pages/PersonDetails.jsx";
import { PersonList } from "./pages/PersonList.jsx";
import Theory from "./pages/Theory.jsx";
import TheoryArticle from "./pages/TheoryArticle.jsx";
import DiagramIntro from "./pages/DiagramIntro.jsx";
import Diagram from "./pages/Diagram.jsx";
import Error500 from "./pages/Error500.jsx";
import Error404 from "./pages/Error404.jsx";
import ComingSoon from "./pages/ComingSoon.jsx";
import Orgs from "./pages/organizations.jsx";
import WhyCare from "./components/WhyCare.jsx";

import "./css/index.scss";
// import "./css/them.scss";

// Set log level based on environment
log.setLevel(
  import.meta.env.NODE_ENV === "development"
    ? log.levels.DEBUG
    : log.levels.INFO
);

/** App component
 * The entry point of the app
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
  const BGs = [
    "https://res.cloudinary.com/dyndxow8x/image/upload/v1732639368/wapaganda/BGs/photo_9_2024-10-23_17-50-44_cx8vo7.jpg",
    "https://res.cloudinary.com/dyndxow8x/image/upload/v1735277102/wapaganda/BGs/photo_3_2024-10-23_17-50-44_gfw1fy.jpg",
    "https://res.cloudinary.com/dyndxow8x/image/upload/v1735277107/wapaganda/BGs/photo_7_2024-10-23_17-50-44_wosprf.jpg",
    "https://res.cloudinary.com/dyndxow8x/image/upload/v1735277138/wapaganda/BGs/photo_1_2024-10-23_18-11-29_mktht9.jpg",
    "https://res.cloudinary.com/dyndxow8x/image/upload/v1735277152/wapaganda/BGs/photo_11_2024-10-23_17-50-44_bqcyhk.jpg",
  ];
  return (
    <div
      className="BG"
      style={{
        backgroundImage: `url("${BGs[0]}")`,
      }}
    >
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<PersonList />} />
          <Route path="/person/:id" element={<PersonDetails />} />
          <Route path="/Why-care" element={<WhyCare />} />
          <Route path="/Media" element={<ComingSoon />} />
          <Route path="/Organizations" element={<Orgs modalState={false} />} />
          <Route
            path="/Organizations/:id"
            element={<Orgs modalState={true} />}
          />
          <Route path="/Acknowledgments" element={<ComingSoon />} />
          <Route path="/Technical-documentation" element={<ComingSoon />} />
          <Route path="/Contacts" element={<ComingSoon />} />
          <Route path="/Telegram" element={<ComingSoon />} />
          <Route path="/Support-the-project" element={<ComingSoon />} />
          <Route path="/theory" element={<Theory modalState={false} />} />
          <Route
            path="/theory/:type/:id"
            element={<Theory modalState={true} />}
          />
          <Route path="/theory/article2/:id" element={<TheoryArticle />} />
          <Route path="/diagramIntro" element={<DiagramIntro />} />
          <Route path="/diagram" element={<Diagram />} />
          <Route path="/500" element={<Error500 />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="*" element={<Error404 />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
