import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import log from "loglevel";

import { useMediaQuery } from "react-responsive";

import { Squash as Hamburger } from "hamburger-react";
import { Button } from "@mui/material";

import "../css/header.scss";

import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import Search from "./Search";
import { useTheme } from "../hooks/useThemeContext";

/** Header component
 * @returns {JSX.Element}
 * @constructor
 */
export const Header = () => {
  /**
   * Handle language change.
   * Change the URL to reflect the chosen language, and save the language in localStorage
   * @param event
   */
  const isPhone = useMediaQuery({ maxWidth: 768 });
  const isTabletPortrait = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isTabletLandscape = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isCompactDesktop = useMediaQuery({ minWidth: 1280, maxWidth: 1440 });
  const isDesktop = useMediaQuery({ minWidth: 1440 });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (e) => {
    const lang = e;
    log.info(`Changing language to ${lang}`);
    i18n
      .changeLanguage(lang)
      .then(() => log.debug("i18n.changeLanguage() done"));
    window.history.pushState({}, "", `?lang=${lang}`); //
    localStorage.setItem("lang", lang);
  };
  const t = useTranslation().t;

  // phone nav
  // toggle to open and close nav menu
  const [menuDisplay, setmenuDisplay] = useState("none");

  // toggle to open and close hamburger button
  const [isOpen, setOpen] = useState(false);

  // close nav menu and hamburger button when clicking on link on phone
  const handleMenuItemClick = () => {
    setmenuDisplay("none");
    setOpen(false);
  };

  // Array of items with path and title for phone nav
  const menuItems = [
    { path: "/Why-care", title: t("Why care") },
    { path: "/", title: t("People") },
    { path: "/media", title: t("Media") },
    { path: "/theory", title: t("Theory") },
    { path: "/diagramIntro", title: t("Diagram") },
    { path: "/organizations", title: t("Organizations") },
  ];

  return (
    <header id="header">
      <nav className="headerNav">
        <a className="logoLink" href="/">
          {(useTheme() === "light-mode" && (
            <img
              className="logo"
              alt="logo"
              src="https://res.cloudinary.com/dyndxow8x/image/upload/v1688258460/wapaganda/Subjective_Agency_Logo_black_lkyi0i.svg"
            />
          )) || (
            <img
              className="logo"
              alt="logo"
              src="https://res.cloudinary.com/dyndxow8x/image/upload/v1684860381/wapaganda/Subjective_Agency_Logo_white_mu9afr.svg"
            />
          )}
          <h4>Wapaganda Project</h4>
        </a>
        {(isDesktop || isCompactDesktop || isTabletLandscape) && (
          <>
            <Search />
            {(isDesktop || isCompactDesktop) && (
              <div className="navMenu">
                <NavLink className={"why"} to="/Why-care">
                  {t("Why care")}
                </NavLink>
                <NavLink to="/">{t("People")}</NavLink>
                <NavLink to="/Media">{t("Media")}</NavLink>
                <NavLink to="/Organizations">{t("Organizations")}</NavLink>
                <NavLink to="/theory">{t("Theory")}</NavLink>
                <NavLink to="/diagramIntro">{t("Diagram")}</NavLink>
                <IconButton
                  aria-label="more"
                  id="lang-button"
                  aria-controls={open ? "lang-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <LanguageIcon />
                </IconButton>

                {/* 
              //? The theme switch button below is currently commented out because the website is only available in dark mode at the moment

              <IconButton id="theme-button" onClick={updateTheme}>
                {(theme === "light-mode" && <NightsStayIcon />) || (
                  <LightModeIcon />
                )}
              </IconButton> */}
                <Menu
                  id="lang-menu"
                  MenuListProps={{
                    "aria-labelledby": "lang-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "20ch",
                      backgroundColor: "#042336",
                      color: "#fff",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleLanguageChange("en");
                      handleClose();
                    }}
                  >
                    <img
                      alt="English Flag"
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
                      }
                      height="18px"
                      width="29px"
                      style={{ margin: "0 5px 0 0" }}
                    />
                    English
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleLanguageChange("ru");
                      handleClose();
                    }}
                  >
                    <img
                      alt="Русский флаг"
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg"
                      }
                      height="18px"
                      width="29px"
                      style={{ margin: "0 5px 0 0" }}
                    />
                    Русский
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleLanguageChange("uk");
                      handleClose();
                    }}
                  >
                    <img
                      alt="Український прапор"
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg"
                      }
                      height="18px"
                      width="29px"
                      style={{ margin: "0 5px 0 0" }}
                    />{" "}
                    Українська
                  </MenuItem>
                </Menu>
              </div>
            )}
          </>
        )}
        {(isTabletLandscape || isTabletPortrait || isPhone) && (
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            onToggle={(toggled) => {
              if (toggled) {
                setmenuDisplay(toggled ? "flex" : "none");
              } else {
                setmenuDisplay(toggled ? "flex" : "none");
              }
            }}
          />
        )}
      </nav>
      <div className="phoneNav" style={{ display: menuDisplay }}>
        <p className="subHeading">Pages</p>
        <ul className="phoneNav__menu">
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <li className="menuItem">
                <Link to={item.path} onClick={handleMenuItemClick}>
                  {item.title}
                </Link>
              </li>
              {index < menuItems.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </ul>
        <br />
        <br />
        <p className="subHeading">Settings</p>
        <div className="box">
          <br />
          <Button
            id="langButton"
            aria-label="more"
            aria-controls={open ? "lang-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <LanguageIcon />
            <span>
              <p> Lang: English</p>
            </span>
          </Button>
          {/*
          //? The theme switch button below is currently commented out because the website is only available in dark mode at the moment
          <Button id="themeButton" onClick={updateTheme}>
            {(theme === "light-mode" && <NightsStayIcon />) || (
              <Brightness7Icon />
            )}
            <span>
              <p> Theme: dark</p>
            </span>
          </Button> */}
          <Menu
            id="lang-menu"
            MenuListProps={{
              "aria-labelledby": "lang-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
                backgroundColor: "#042336",
                color: "#fff",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleLanguageChange("en");
                handleClose();
              }}
            >
              <img
                alt="English Flag"
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
                }
                height="18px"
                width="29px"
                style={{ margin: "0 5px 0 0" }}
              />
              English
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLanguageChange("ru");
                handleClose();
              }}
            >
              <img
                alt="Русский флаг"
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg"
                }
                height="18px"
                width="29px"
                style={{ margin: "0 5px 0 0" }}
              />
              Русский
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLanguageChange("uk");
                handleClose();
              }}
            >
              <img
                alt="Українська прапор"
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg"
                }
                height="18px"
                width="29px"
                style={{ margin: "0 5px 0 0" }}
              />{" "}
              Українська
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};
