import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { fetchPresignedUrls } from "../hooks/RestClient";
import "../css/WhyCare.scss";
import Loading from "./Loading";
import Error from "./Error";

function parseUrlToHeadersAndBaseUrl(rawUrl) {
  const url = new URL(rawUrl);
  const baseUrl = url.origin + url.pathname;

  const headers = {};
  for (const [key, value] of url.searchParams.entries()) {
    headers[key] = value;
  }

  return { baseUrl, headers };
}

const WhyCare = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [markdown, setMarkdown] = useState("");
  const t = useTranslation().t;
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchLocalMarkdown = async () => {
      const localMarkdownPath = `/Why_Care_markdown/Why_Care_${[t("lang")]}.md`;

      try {
        console.info("fetch local Markdown");

        const response = await fetch(localMarkdownPath);
        const text = await response.text();
        setMarkdown(text);
      } catch (error) {
        console.error("Error fetching local Markdown:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    const fetchMarkdown = async () => {
      const urls = await fetchPresignedUrls("whyCare");
      const langCode = i18n.language;
      const markdownUrl = urls[langCode];

      if (markdownUrl) {
        try {
          setLoading(true);
          setError(false);
          const url = new URL(markdownUrl);
          const { baseUrl, headers } = parseUrlToHeadersAndBaseUrl(markdownUrl);
          console.log("Original url:", markdownUrl);
          console.log("Base URL:", baseUrl);
          console.log("Headers:", headers);

          // const response = await fetch(baseUrl, {method: "GET", headers: headers });
          const response = await fetch(markdownUrl, {
            method: "GET",
            credentials: "include",
          });
          console.log("Markdown response:", response);
          const text = await response.text();
          setMarkdown(text);
        } catch (error) {
          console.error("Error fetching markdown:", error);
          // setError(true);
          //? local backup
          fetchLocalMarkdown();
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMarkdown();
  }, [i18n.language, t]);

  return (
    <div className="whyCare">
      <div className="pageHeader">
        <h2>{t("Why care")}</h2>
      </div>

      {loading ? (
        <div className="loadingContinuer">
          <Loading />
        </div>
      ) : error ? (
        <Error />
      ) : (
        <div className="continuer">
          <div className="text">
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhyCare;
