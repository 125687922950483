import log from "loglevel";
import crypto from "crypto";

/**
 * fetch the base back-end URL
 */
let BACKEND_URL = import.meta.env.VITE_BACKEND_URL || "http://localhost:11000";
let AUTH_URL = import.meta.env.VITE_AUTH_URL || "https://a.subjective.agency";

// let SEARCH_URL = import.meta.env.VITE_SEARCH_URL || "http://localhost:11001";

let AUTH_USERNAME = import.meta.env.VITE_AUTH_USERNAME;
let AUTH_PASSWORD = import.meta.env.VITE_AUTH_PASSWORD;

const fetchAuthToken = async () => {
  try {
    console.info("Attempting to log in");

    // Hash the password using SHA-256
    // const hashedPassword = crypto.createHash('sha256').update(AUTH_PASSWORD).digest('hex');

    const response = await fetch(`${AUTH_URL}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: AUTH_USERNAME,
        password: AUTH_PASSWORD,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();

    // Token is now handled by HTTP-only cookie, no need to set it manually
    // But we might want to store the user_id or handle the success message
    if (data.user_id) {
      console.info("Login successful");
    }
  } catch (error) {
    console.error("Error logging in:", error);
  }
};

fetchAuthToken();

/**
 * Define the function to switch the URL based on the current environment
 * Requirement is being set with the environment variable VITE_APP_NODE_ENV
 * If the environment variable is not set, the default value is "development"
 */

export function personsUrl() {
  let url = BACKEND_URL + "/people";
  console.log("Current URL: ", url);
  return url;
}

/** This function first checks if the data property of the response is an array.
 * If it is, it creates a transformed response object with total and page_size set
 * to the length of the data array. The data array is mapped to a new array with the desired format.
 * If the data property is not an array, it assumes that the format is paginated,
 * and creates a transformed response object with total and page_size set to the total
 * and length of the page array, respectively.
 * The page array is also mapped to a new array with the desired format.
 *
 * @param response one of the response JSONs from the REST API
 * @returns {{total, data: *, page_size: number}}
 */

export function transformPeopleResponse(response) {
  if (Array.isArray(response)) {
    return {
      total: response.page.length,
      page_size: 20,
      data: response.page.map((item) => {
        return {
          id: item.id,
          fullname_en: item.attributes.fullname.en
            ? item.attributes.fullname.en
            : "",
          fullname_ru: item.attributes.fullname.ru
            ? item.attributes.fullname.ru
            : "",
          fullname_uk: item.attributes.fullname.uk
            ? item.attributes.fullname.uk
            : "",
          dob: item.attributes.dob,
          dod: item.attributes.dod,
          photo: item.attributes.photo,
          thumb: item.attributes.thumb,
          timestamp: item.attributes.added_on,
          aliases: item.aliases,
        };
      }),
    };
  } else {
    return {
      total: response.total,
      page_size: response.page_size,
      data: response.page.map((item) => {
        return {
          id: item.id,
          fullname_en: item.fullname.en ? item.fullname.en : "",
          fullname_ru: item.fullname.ru ? item.fullname.ru : "",
          fullname_uk: item.fullname.uk ? item.fullname.uk : "",
          dob: item.dob,
          dod: item.dod,
          sex: item.sex,
          known_for: item.known_for,
          photo: item.photo,
          thumb: item.thumb,
          aliases: item.aliases,
        };
      }),
    };
  }
}

/**
 * Get only the desired attributes from the "person" response,
 * basically, just get rid of some service attributes and put all values in the root of the object
 * @param response
 * @returns {*&{id}}
 */
export function transformPersonResponse(response) {
  const person_data = response.data.person;
  const airtime = response.data.airtime;
  return {
    ...person_data,
    airtime: airtime,
    dd: person_data,
  };
}

/**
 * Send asynchronous POST request to REST API to populate `persons` array
 *
 * @param endpoint_url
 * @param page
 * @param page_size
 * @param filters
 * @returns {Promise<*>}
 */

export function requestPersons(endpoint_url, page, page_size, filters) {
  const url = new URL(endpoint_url);
  url.searchParams.append("page", page);
  url.searchParams.append("page_size", page_size);
  url.searchParams.append("sort_by", filters.sort_by);
  url.searchParams.append("sort_direction", filters.sortingDirection);
  if (filters.sex !== null) url.searchParams.append("sex", filters.sex);
  if (filters.status !== null) url.searchParams.append("alive", filters.status);
  if (filters.age_min !== null)
    url.searchParams.append("age_min", filters.age_min);
  if (filters.age_max !== null)
    url.searchParams.append("age_max", filters.age_max);

  console.log("Sending request to:", url.toString());

  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data ? transformPeopleResponse(data) : [];
    })
    .catch((error) => {
      console.error("Error fetching persons:", error);
      return [];
    });
}

export function requestPeopleFilters(endpoint_url) {
  const url = new URL(endpoint_url);
  url.pathname += "/filters";

  console.log("Sending request to:", url.toString());

  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching people filters:", error);
      return [];
    });
}

/**
 * The function request information about a single patient
 * If the request is successful and the response is valid JSON, the function returns the parsed JSON data.
 * If there is an error in the request or the response, the function catches the error
 * and returns an error object with the error message.
 *
 * @param endpoint_url
 * @param id
 * @returns {Promise<{error}|any>}
 */
export function requestPerson(endpoint_url, id) {
  const url = new URL(endpoint_url);
  url.pathname += `/${id}`;
  url.searchParams.append("storage_bucket", "photos-l");
  url.searchParams.append("type", "person");
  url.searchParams.append("person_id", id);
  url.searchParams.append("expire_on_commit", "true");

  console.log("Sending request to:", url.toString());

  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching person:", error);
      return [];
    });
}

// theory endpoint

export function theoryUrl() {
  let url = BACKEND_URL + "/theory";
  console.log("Current URL: ", url);
  return url;
}

/**
 * Fetches a list of theory articles from the theoryUrl.
 *
 * @param {string} endpoint_url
 * @param {object} filters
 * @returns {Promise} A promise that resolves to the retrieved theory articles data.
 */
export function theoryArticles(endpoint_url, filters) {
  const url = new URL(endpoint_url);
  url.searchParams.append("page", filters.currentPage);
  url.searchParams.append("page_size", filters.pageSize);
  if (filters.sortBy != null && filters.sortBy != undefined) {
    url.searchParams.append("sort_by", filters.sortBy);
  } else {
    url.searchParams.append("sort_by", "added_at");
  }

  if (
    filters.sortingDirection != null &&
    filters.sortingDirection != undefined
  ) {
    url.searchParams.append("sort_direction", filters.sortingDirection);
  } else {
    url.searchParams.append("sort_direction", "asc");
  }
  // if (filters.sortBy != null && filters.sortBy != undefined)
  //   url.searchParams.append("sort_by", filters.sortBy);
  // if (filters.sortingDirection != null && filters.sortingDirection != undefined)
  //   url.searchParams.append("sort_direction", filters.sortingDirection);

  if (filters.tagsSelected != null && filters.tagsSelected != undefined)
    url.searchParams.append("tag_id", filters.tagsSelected);

  console.log("Sending request to:", url.toString());

  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching theoryArticles:", error);
      return [];
    });
}

/**
 * Fetches a specific article using the provided theory URL and article ID.
 *
 * @param  {string} endpoint_url
 * @param {number} id
 * @returns {Promise}
 */
export function requestArticle(endpoint_url, id) {
  const url = new URL(endpoint_url);
  url.pathname += `/${id}`;
  url.searchParams.append("id", id);

  console.log("Sending request to:", url.toString());

  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching persons:", error);
      return [];
    });
}

// organizations endpoint

export function orgUrl() {
  let url = BACKEND_URL + "/organizations";
  console.log("Current URL: ", url);
  return url;
}

/**
 *Fetches a list organizations from orgUrl.
 * @param {string} endpoint_url
 * @param {object} filters
 * @returns
 */
export function requestOrgs(endpoint_url, filters) {
  const url = new URL(endpoint_url);
  url.searchParams.append("page", filters.currentPage);
  url.searchParams.append("page_size", filters.pageSize);
  if (filters.sortBy !== undefined && filters.sortBy !== null) {
    url.searchParams.append("sort_by", filters.sortBy);
  }
  if (
    filters.sortingDirection !== undefined &&
    filters.sortingDirection !== null
  ) {
    url.searchParams.append("sort_direction", filters.sortingDirection);
  }

  console.log("Sending request to:", url.toString());
  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching organizations:", error);
      return [];
    });
}

/**
 * Fetches a specific organization details using the provided orgUrl and article ID.
 * @param {string} endpoint_url
 * @param {number} id
 * @returns
 */
export function requestOrg(endpoint_url, id) {
  const url = new URL(endpoint_url);
  url.pathname += `/${id}`;
  url.searchParams.append("organization_id", id);
  url.searchParams.append("expire_on_commit", "true");

  console.log("Sending request to:", url.toString());

  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching organization:", error);
      return [];
    });
}

export function searchUrl() {
  let url = BACKEND_URL + "/search";
  console.log("Current URL: ", url);
  return url;
}

export function requestSearch(endpoint_url, input) {
  const url = new URL(endpoint_url);
  // url.pathname += `/search`;
  url.searchParams.append("search_query", input);
  // url.searchParams.append("full", false);
  // https://s.subjective.agency/db?search_query=Abusalam&full=false

  console.log("Sending request to:", url.toString());

  return fetch(url.toString(), {
    method: "POST",
    credentials: "include",

    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error requesting search:", error);
      return [];
    });
}

export function fetchPresignedUrls(textId) {
  const url = `${BACKEND_URL}/txt`;

  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ text_id: textId }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .catch((error) => {
      console.error("Error fetching presigned URLs:", error);
      return {};
    });
}
