import React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Menu } from "@mui/material";

import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useTheme } from "../hooks/useThemeContext";

export const FilterMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => {
  return {
    "& .MuiPaper-root": {
      borderRadius: 0,
      marginTop: theme.spacing(1),
      minWidth: 180,
      backgroundColor:
        useTheme() === "dark-mode" ? "rgba(5, 26, 40, 0.8)" : "#fff",
      color: useTheme() === "dark-mode" ? "#fff" : "#1e1e1e",
      backdropFilter: "blur(6px)",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiTypography-caption": {
        color: useTheme() === "dark-mode" ? "#fff" : "#1e1e1e",
      },
      "& .MuiMenuItem-root": {
        "& .MuiCheckbox-root": {
          padding: "0 16px",
        },
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color:
            theme.palette.mode === "dark-mode"
              ? "rgb(55, 65, 81)"
              : theme.palette.grey[300],
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
        "&:hover": {
          backgroundColor:
            useTheme() === "dark-mode" ? "rgba(5, 26, 40, 0.8)" : "#eee",
        },
      },
    },
  };
});

const lightStyle = {
  multiValueLabel: (styles) => ({
    ...styles,
  }),
};

const darkStyle = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#051C2B",
    borderColor: "#051C2B",
    ":hover": {
      ...styles[":active"],
      borderColor: "#051A28",
    },
  }),

  clearIndicator: (styles) => ({ ...styles, backgroundColor: "#051C2B" }),

  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#1C3C50",
    };
  },
  multiValueRemove: (styles) => ({
    ...styles,

    ":hover": {
      ...styles[":active"],
      backgroundColor: "red",
    },
  }),
  container: (styles) => {
    return {
      ...styles,
      backgroundColor: "rgba(5, 26, 40, 0.6)",
      backdropFilter: "blur(6px)",
    };
  },
  option: (styles) => {
    return {
      ...styles,
      color: "black",
      backgroundColor: "#042336",
      ":hover": {
        ...styles[":active"],
        backgroundColor: "rgba(0,0,0,0.2)",
      },
      ":active": {
        ...styles[":active"],
        backgroundColor: "red",
      },
    };
  },
};
export const MultiSelectFilter = ({ labelName, setOption }) => {
  const t = useTranslation().t;

  const handleMultiSelect = (e, setIds) => {
    const selectedIds = e.map((bandle) => bandle.value);
    setIds(selectedIds);
  };
  return (
    <div className="select-container">
      <label htmlFor="aria-example-input">{labelName}</label>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        placeholder="all"
        // options={optionsData}
        onChange={(e) => {
          handleMultiSelect(e, setOption);
        }}
        isMulti
        styles={useTheme() === "dark-mode" ? darkStyle : lightStyle}
        noOptionsMessage={() => t("no options")}
      />
    </div>
  );
};

export const SelectFilter = ({ labelName, optionsData, setOption }) => {
  return (
    <div className="select-container">
      <label htmlFor="aria-example-input">{labelName}</label>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        placeholder="all"
        options={optionsData}
        onChange={(e) => setOption(e.value)}
        styles={useTheme() === "dark-mode" ? darkStyle : lightStyle}
      />
    </div>
  );
};
