import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "../css/footer.scss";
import { useTheme } from "../hooks/useThemeContext";

export const Footer = () => {
  const t = useTranslation().t;
  return (
    <footer id="footer">
      <a className="logoLink" href="/">
        {(useTheme() === "light-mode" && (
          <img
            className="logo"
            alt="logo"
            src="https://res.cloudinary.com/dyndxow8x/image/upload/v1688258460/wapaganda/Subjective_Agency_Logo_black_lkyi0i.svg"
          />
        )) || (
          <img
            className="logo"
            alt="logo"
            src="https://res.cloudinary.com/dyndxow8x/image/upload/v1684860381/wapaganda/Subjective_Agency_Logo_white_mu9afr.svg"
          />
        )}
        <h4>Wapaganda</h4>
        <p>{t("By")} subjective.agency</p>
      </a>
      <div className="links">
        <NavLink to="/Acknowledgments">{t("Acknowledgments")}</NavLink>
        <NavLink to="/Technical-documentation">
          {t("Technical Documentation")}
        </NavLink>
        <NavLink to="/About">{t("About Us")}</NavLink>
        <NavLink to="/Contacts">{t("Contacts")}</NavLink>
        <NavLink to="/Telegram">{t("Telegram")}</NavLink>
        <NavLink to="/Support-the-project">{t("Support the Project")}</NavLink>
      </div>
    </footer>
  );
};
